import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./assets/css/productStyle.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import PageScrollTop from "./component/PageScrollTop";
import Footer from "./component/footer/Footer";
import FarmvalyHome from "./home/FarmvalyHome";
import ProductDetails from "./component/product/ProductDetails";
import productsdata from "./data/productsdata";
import Login from "./component/Login";
import AboutUs from "./home/AboutUs";
import OurBusiness from "./home/OurBusiness";
import ShopPage from "./home/ShopPage";
import ContactUs from "./home/ContactUs";
import Products from "./home/Products";
import Bangladesh from "./home/Bangladesh";
import error404 from "./elements/error404";
import Article from "./home/Article";
import ArticleDetails from "./elements/ArticleDetails";
import NewsDetails from "./elements/NewsDetails";
import Export from "./home/Export";
import LocalTrading from "./home/LocalTrading";
import OnlineBusiness from "./home/OnlineBusiness";
import PrivacyPolicy from "./home/PrivacyPolicy";
import TermsConditions from "./home/TermsConditions";
import Certifications from "./home/Certifications";
import LifestyleBrand from "./home/LifestyleBrand";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from "react-ga4";

class Root extends Component {
  state = {
    isAuthenticated: false,
  };

  handleLogin = (status) => {
    this.setState({ isAuthenticated: status });
  };

  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_G_A_MEASUREMENT_ID);
    ReactGA.send("pageview");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isAuthenticated !== prevState.isAuthenticated) {
      ReactGA.send(
        "pageview",
        window.location.pathname + window.location.search
      );
    }
  }

  render() {
    const { isAuthenticated } = this.state;

    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          {!isAuthenticated ? (
            <>
              <Switch>
                <Route exact path="/" component={FarmvalyHome} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route exact path="/our-business" component={OurBusiness} />
                <Route exact path="/shop" component={ShopPage} />
                <Route exact path="/contact" component={ContactUs} />
                <Route exact path="/products" component={Products} />
                <Route exact path="/article" component={Article} />
                <Route
                  exact
                  path="/article-details/:id"
                  component={ArticleDetails}
                />
                <Route exact path="/news-details/:id" component={NewsDetails} />
                <Route exact path="/our-business/export" component={Export} />
                <Route
                  exact
                  path="/our-business/local-trading"
                  component={LocalTrading}
                />
                <Route
                  exact
                  path="/our-business/online-business"
                  component={OnlineBusiness}
                />
                <Route
                  exact
                  path="/our-business/lifestyle-brand"
                  component={LifestyleBrand}
                />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route
                  exact
                  path="/terms-&-conditions"
                  component={TermsConditions}
                />
                <Route
                  exact
                  path="/certifications"
                  component={Certifications}
                />
                <Route
                  exact
                  path="/product/:id"
                  render={(props) => (
                    <ProductDetails {...props} products={productsdata} />
                  )}
                />
                <Route component={error404} />
              </Switch>
              <Footer />
            </>
          ) : (
            <Login onLogin={this.handleLogin} />
          )}
        </PageScrollTop>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
